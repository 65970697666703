import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import PrintHeader from '../General/PrintHeader';
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from '../General/Utility';
import { scrollToTop } from '../Shared/ScrollOnTop';

const PrintCollectionReport = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listOfLedger, setLedger] = useState([]);
    const [StartDate, setStartDate] = useState("");
    const [EndDate, setEndDate] = useState("");

    let totalReceipts = 0;

    useEffect(() => {
        setLoading(true);
        // Get the URL parameters
        const urlParams = new URLSearchParams(window.location.search);

        // Retrieve individual parameter values
        const start = urlParams.get('start');
        const end = urlParams.get('end');

        setStartDate(moment(start).format(config.date_format));
        setEndDate(moment(end).format(config.date_format));


        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            StartDate: start,
            EndDate: end,
            pageSize: 10000,

        };




        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'FeeSystem/GetCollectionReportNew',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(data);

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);


            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);

    const renderLedger = () => {
        if (listOfLedger.length != null && listOfLedger.length > 0) {
            let serial = 1;
            return listOfLedger.map((item, index) => {
                totalReceipts += item.Student_ID;
                return (
                    <tr key={index}>
                        <td>{serial++}</td>
                        <td>{moment(item.ReceivedDate).format(config.date_format)}</td>
                        {/* <td>{moment(item.ReceivedDate).format("MMM YYYY")}</td> */}
                        <td>{item.Student_ID}</td>
                        <td>{item.ReceivedAmount}</td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="5">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };


    return (
        <>
            <Page orientation="potrait" size="a4">

                <PrintHeader title={`Collection Report from ${StartDate} to ${EndDate} `} />
                <table className="table table-sm table-bordered table-print">
                    <thead>
                        <tr>

                            <th>Sr.#</th>
                            <th>Dated </th>
                            {/* <th>Month</th> */}
                            <th>No. of Receipts </th>
                            <th>Total Deposit</th>


                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ?
                                <tr>
                                    <td className="text-center" colSpan="6">
                                        <Spinner animation="border" role="status"></Spinner>
                                    </td>
                                </tr>
                                :
                                <>
                                    {renderLedger()}
                                    <tr>
                                        <td></td>
                                        <td colSpan={1} className="text-end">Total:</td>
                                        <td >{totalReceipts}</td>

                                        <td  >{calculateColumnTotal(listOfLedger, 'ReceivedAmount')}</td>
                                    </tr>
                                </>

                        }

                    </tbody>
                </table>


            </Page>
        </>
    );
}

export default PrintCollectionReport;
