import ReportsMenu from "./ReportsMenu";
import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import StudentGroupDropDown from "../Shared/StudentGroupDropDown";
import StudentStatusDropDown from "../Shared/StudentStatusDropDown";
import { toast, ToastContainer } from "react-toastify";
import { NavLink, Link, useParams } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import { Form, PageItem } from "react-bootstrap";
import MonthsDropDown from "../Shared/MonthsDropDown";
import YearsDropDown from "../Shared/YearsDropDown";
import { calculateColumnTotal, getMonthName } from "../General/Utility";
import { scrollToTop } from "../Shared/ScrollOnTop";
import csvDownload from "json-to-csv-export";
import BusrouteDropDown from "../Shared/BusrouteDropDown";

const RoutesReportDetails = () => {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(
        localStorage.getItem("MemberType")
    );
    //const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));

    const [showFilters, setShowFilters] = useState(true);
    const urlParams = new URLSearchParams(window.location.search);
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [validated, setValidated] = useState(false);
    const [listOfLedgerforcvs, setLedgerforcvs] = useState([]);
    const [loaddata, setLoaddata] = useState(false);
    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    const [Wing, setWing] = useState(urlParams.get("Wing"));
    const [Section, setSection] = useState("");
    const [SessionID, setSessionID] = useState("");
    const [Session, setSession] = useState("");
    const [Class, setClass] = useState(urlParams.get("Class"));
    const [ReportMonth, setReportMonth] = useState(urlParams.get("Month"));
    const [ReportYear, setReportYear] = useState(urlParams.get("Year"));
    const [RouteID, setRouteID] = useState(0);
    const [RouteCode, setRouteCode] = useState(params.routeCode);

    document.title = "Bus Routes Report Details";
    // console.log(RouteCode);
    // setRouteCode(params.routeCode);

    // for filters

    useEffect(() => {
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: pageNo,
            pageSize: pageSize,
            ReportYear: ReportYear,
            ReportMonth: ReportMonth,
            Wing_Id: Wing,
            Class_Id: Class,
            Section_Id: Section,
            Registration_No: RouteCode,
        };
        //console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "/Reports/GetRoutesReportDetails",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        //console.log(data);

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);
            })
            .catch(function (error) {
                //console.log(error);
            });
    }, [pageNo, pageSize, ReportYear, ReportMonth, Wing, Class, Section, RouteID]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const handleSubmitForCvs = (event) => {
        //console.log(event);

        setLoaddata(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: pageNo,
            pageSize: 1000000,
            ReportYear: ReportYear,
            ReportMonth: ReportMonth,
            Wing_Id: Wing,
            Class_Id: Class,
            Section_Id: Section,
            Registration_No: RouteCode,
        };

        //console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "/Reports/GetRoutesReportDetails",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    if (response.data.totalRecords > 0) {
                        setLedgerforcvs(response.data.ListofRecords);
                        let data = response.data.ListofRecords;
                        let a = 1;
                        const listOfLedgerNew = data.map(({ ...item }) => ({
                            Serial_No: a++,
                            Registration_No: item.Selected_Student.Registration_No,
                            Student_Name: item.Selected_Student.Student_Name,
                            Class: item.Selected_Student.Contact_Email + "-" + item.Selected_Student.Contact_Phone,
                            Bus_Route: item.Selected_Student.Bay_Form_No,
                            Fee_Posted: item.Fee_Posted,
                            ConcessionAmount: item.ConcessionAmount,
                            ReceiveableFee: item.ReceiveableFee,
                            ReceivedAmount: item.ReceivedAmount,
                            PendingFee: item.PendingFee,
                        }));
                        //console.log(listOfLedgerNew);

                        const dataToConvert = {
                            data: listOfLedgerNew,
                            filename: "ConcessionReport",
                            delimiter: ",",
                            headers: [
                                "Serial No",
                                "Registration_No",
                                "Student Name",
                                "Class",
                                "Bus Route",
                                "Fee Posted",
                                "Concession Amount",
                                "Receiveable Fee",
                                "Received Amount",
                                "Pending Fee",
                            ],
                        };
                        csvDownload(dataToConvert);

                        setLoaddata(false);
                    } else if (response.data.totalRecords == 0) {
                        toast.error("There is no data available for download", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setLoaddata(false);
                    }
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoaddata(false);
                }
            })
            .catch(function (error) {
                toast.error("Network Error..", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setLoaddata(false);
            });
    };

    const renderLedger = () => {
        if (listOfLedger != null && listOfLedger.length > 0) {
            return listOfLedger.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.Selected_Student.Registration_No}</td>
                        <td>{item.Selected_Student.Student_Name}</td>
                        <td>{item.Selected_Student.Contact_Email} - {item.Selected_Student.Contact_Phone}</td>
                        <td>{item.Selected_Student.Bay_Form_No}</td>
                        <td>{item.Fee_Posted}</td>
                        <td>{item.ConcessionAmount}</td>
                        <td>{item.ReceiveableFee}</td>
                        <td>{item.ReceivedAmount}</td>
                        <td>{item.PendingFee}</td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="10">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };

    return (
        <>
            <ReportsMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/Dashboard">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/Reports/ReportsDashboard">Reports</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/Reports/RoutesReport">Bus Route Reports</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Bus Routes Report Details
                                    </li>
                                </ol>
                            </nav>
                            <div className="page-title">Bus Routes Report Details</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <table className="table table-theme">
                                <thead>
                                    <tr>
                                        <th colSpan={9}>Total Records Found: {totalRecords}</th>
                                        <th className="text-end">
                                            {/* Total Amount : {calculateColumnTotal(listOfLedger, 'Opening_Balance')} */}
                                        </th>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th>Serial No</th>
                                        <th>Registration No</th>
                                        <th>Student Name</th>
                                        <th>Class</th>
                                        <th>Bus Route</th>
                                        <th>Fee Posted</th>
                                        <th>Concession</th>
                                        <th>Receiveable</th>
                                        <th>Received</th>
                                        <th>Pending</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr>
                                            <td className="text-center" colSpan="15">
                                                <Spinner animation="border" role="status"></Spinner>
                                            </td>
                                        </tr>
                                    ) : (
                                        <>{renderLedger()}</>
                                    )}
                                    <tr>
                                        <td colSpan={5}> <b> Total Amount :</b></td>
                                        <td className="">
                                            <b>
                                                {calculateColumnTotal(listOfLedger, "Fee_Posted")}
                                            </b>
                                        </td>
                                        <td className="">
                                            <b>

                                                {calculateColumnTotal(listOfLedger, "ConcessionAmount")}
                                            </b>
                                        </td>
                                        <td className="">
                                            <b>

                                                {calculateColumnTotal(listOfLedger, "ReceiveableFee")}
                                            </b>
                                        </td>
                                        <td className="">
                                            <b>

                                                {calculateColumnTotal(listOfLedger, "ReceivedAmount")}
                                            </b>
                                        </td>
                                        <td className="">
                                            <b>

                                                {calculateColumnTotal(listOfLedger, "PendingFee")}
                                            </b>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {totalRecords > 10 && (
                                <div className="pagination-wrap">
                                    <div className="row align-items-center">
                                        <div className="col-md-2 form_default">
                                            <div className="d-flex align-items-center">
                                                <label className="me-2" style={{ fontSize: "15px" }}>
                                                    Page Size
                                                </label>
                                                <div className="form-group">
                                                    <select
                                                        className="form-select"
                                                        style={{ width: "100px" }}
                                                        onChange={(e) => {
                                                            setPageNo(1);
                                                            setPageSize(parseInt(e.target.value));
                                                        }}
                                                    >
                                                        <option value="10" selected>
                                                            10
                                                        </option>
                                                        <option value="20">20</option>
                                                        <option value="50">50</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <Pagination
                                            pageNo={pageNo}
                                            pageSize={pageSize}
                                            totalRecords={totalRecords}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="card mt-2">
                        <div className="card-body p-md-4">
                            <div className="row">
                                <div className="col-md-12 text-end">
                                    <button
                                        className="button button-primary me-4"
                                        onClick={handleSubmitForCvs}
                                    >
                                        Download Excel
                                    </button>

                                    <NavLink
                                        className="button button-primary"
                                        to={`/Reports/PrintRoutesReportDetail?ReportYear=${ReportYear}&ReportMonth=${ReportMonth}&Wing_Id=${Wing}&Class_Id=${Class}&Section_Id=${Section}&Class_Id=${Class}&RouteCode=${RouteCode}
                                       `}
                                        target="_blank"
                                    >
                                        Print Report
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RoutesReportDetails;