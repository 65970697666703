import ReportsMenu from "./ReportsMenu";
import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import WingsDropDown from "../Shared/WingsDropDown";
import { toast, ToastContainer } from "react-toastify";
import { NavLink, Link } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import { Form, PageItem } from "react-bootstrap";
import MonthsDropDown from "../Shared/MonthsDropDown";
import YearsDropDown from "../Shared/YearsDropDown";
import FeetypesDropDown from "../Shared/FeeTypesDropDown";
import { scrollToTop } from "../Shared/ScrollOnTop";
import csvDownload from "json-to-csv-export";
import Select from "react-select";

const FeeReporting = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);
  const [listOfLedgerforcvs, setLedgerforcvs] = useState([]);
  const [loaddata, setLoaddata] = useState(false);
  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [WingId, setWingId] = useState(0);
  const [SessionId, setSessioinId] = useState(
    localStorage.getItem("DefaultSession")
  );
  const [isUpdated, setIsUpdated] = useState(false);
  const [ReportMonth, setReportMonth] = useState(moment().format("MM"));
  const [ReportYear, setReportYear] = useState(moment().format("YYYY"));
  const [DebitSum, setDebitSum] = useState(0);
  const [CreditSum, setCreditSum] = useState(0);
  const [FeeTypes, setFeeTypes] = useState([]);
  const [FeetypeID, setFeetypeID] = useState(0);
  const [TotalReceived, setTotalReceived] = useState(0);
  const [receivedCount, setreceivedCount] = useState(0);
  const [TotalReceiveableFee, setTotalReceiveableFee] = useState(0);
  const [TotalBalance, setTotalBalance] = useState(0);

  useEffect(() => {
    document.title = "Fee Posting Detailed Report";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      ReportMonth: ReportMonth,
      ReportYear: ReportYear,
      Wing_Id: WingId,
      FeeTypeId: FeetypeID,
    };

    //////console.log(">>>>>",data);

    var api_config = {
      method: "post",
      url: config.base_url + "Reports/GetFeeReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
          setDebitSum(response.data.DebitSum);
          setCreditSum(response.data.CreditSum);
          setTotalBalance(response.data.TotalBalance);
          setTotalReceived(response.data.TotalReceived);
          setreceivedCount(response.data.receivedCount);
          setTotalReceiveableFee(response.data.TotalReceiveableFee);
        }
        else {
          setLedger([]);
          setLoading(false);
        }
      })
      .catch(function (error) {
        //////console.log(error);
        setLoading(false)
      });
  }, [pageNo, pageSize, WingId, FeetypeID, ReportMonth, ReportYear]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handleSubmitForCvs = (event) => {
    //////console.log(event);

    setLoaddata(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: 100000,
      ReportMonth: ReportMonth,
      ReportYear: ReportYear,
      Wing_Id: WingId,
      FeeTypeId: FeetypeID,
    };

    //////console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Reports/GetFeeReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        ////////console.log(response.data);
        if (response.data.status_code == 1) {
          if (response.data.totalRecords > 0) {
            setLedgerforcvs(response.data.ListofRecords);

            let data = response.data.ListofRecords;
            let count = 1;
            const listOfLedgerNew = data.map(({ ...rest }) => ({
              Serial_No: count++ + pageSize * (pageNo - 1),
              Student_ID: rest.Student_ID,
              RegNo: rest.Registration_No,
              Name: rest.Student_Name,
              Father_Name: rest.Father_Name,
              Class_Name: rest.Class_Name + " - " + rest.Section_Name,
              Fee_Name: rest.Father_CNIC,
              Concession_Rule_ID: rest.Contact_Email,
              FeePosted: rest.FeePosted,
              ConcessionAmount: rest.ConcessionAmount,
              ReceiveableFee: rest.ReceiveableFee,
              ReceivedAmount: rest.ReceivedAmount,
              PendingFee: rest.PendingFee,
            }));
            //////console.log(listOfLedgerNew);

            const dataToConvert = {
              data: listOfLedgerNew,
              filename: "FeeReporting",
              delimiter: ",",
              headers: [
                "Serial_No",
                "ID",
                "RegNo",
                "Name",
                "Father_Name",
                "Class_Name",
                "Fee_Name",
                "CS",
                "Fee",
                "Concession",
                "Receiveable Fee",
                "Received",
                "Balance",
              ],
            };
            csvDownload(dataToConvert);

            setLoaddata(false);
          } else if (response.data.totalRecords == 0) {
            toast.error("There is no data available for download", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoaddata(false);
          }
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoaddata(false);
        }
      })
      .catch(function (error) {
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoaddata(false);
      });
  };
  const renderLedger = () => {
    ////console.log(listOfLedger);
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>
              <NavLink
                target="_blank"
                to={"/Students/StudentLedger/" + item.Student_ID}
                className="red"
              >
                {item.Student_ID}
              </NavLink>
            </td>
            <td>{item.Registration_No}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Father_Name}</td>
            {/*<td>{item.Wing_Name}</td> */}
            <td>{item.Class_Name} - {item.Section_Name}</td>
            <td>{item.Father_CNIC}</td>
            <td>{ReportMonth}</td>
            <td>{ReportYear}</td>
            <td>{item.Contact_Email}</td>
            <td>{item.FeePosted}</td>
            <td>{item.ConcessionAmount}</td>
            <td>{item.ReceiveableFee}</td>
            <td>{item.ReceivedAmount}</td>
            <td>{item.PendingFee}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="15">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  useEffect(() => {
    setLoading(true);
    const apiData = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      PageNo: 1,
      PageSize: 100,
      SchoolID: 1,
    };

    ////////console.log(apiData);

    var api_config = {
      method: "post",
      url: config.base_url + "FeeType/GetFeeTypes",
      headers: {
        "Content-Type": "application/json",
      },
      data: apiData,
    };

    axios(api_config)
      .then(function (response) {
        //////console.log(response.data);

        setFeeTypes(response.data.ListofRecords);
        setLoading(false);
      })
      .catch(function (error) {
        //////console.log(error);
      });
  }, []);
  //////console.log(FeeTypes);
  const dropdownOptions = FeeTypes.map((item) => ({
    value: item.Fee_Type_ID,
    label: item.Fee_Type_Name + "(" + item.Fee_Type_Code + ")",
    code: item.Fee_Type_Code,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%", // Set your desired width here
    }),
  };

  const handleDropdownChange = (selectedOption) => {
    //let label = selectedOption.label;
    setFeetypeID(selectedOption.value);
  };

  ////console.log(FeetypeID);

  return (
    <>
      <ToastContainer />
      <ReportsMenu />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/Reports/ReportsDashboard">Reports</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Fee Reporting
                  </li>
                </ol>
              </nav>
              <div className="page-title">Fee Reporting</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`collapse ${showFilters ? "show" : ""} `}
        id="collapseFilteres"
      >
        {/* <Form noValidate validated={validated} onSubmit={handleSubmit}> */}
        <div className="page-content">
          <div className="container">
            <div className="card mb-2">
              <div className="card-body p-md-4">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="">Select Month:</label>
                      <select
                        name="ReportMonth"
                        onChange={(e) => setReportMonth(e.target.value)}
                        className="form-select"
                      >
                        <option value="" disabled="" selected="selected">
                          Please Select
                        </option>
                        <MonthsDropDown />
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="">Select Year:</label>
                      <select
                        name="ReportYear"
                        onChange={(e) => setReportYear(e.target.value)}
                        className="form-select"
                      >
                        <option value="" disabled="" selected="selected">
                          Please Select
                        </option>
                        <YearsDropDown />
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label>Select Wing:</label>
                      <select
                        name="Wing_Id"
                        onChange={(e) => setWingId(e.target.value)}
                        className="form-select"
                      >
                        <option value="0">---All---</option>
                        <WingsDropDown />
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Select Fee Type:</label>
                      <Select
                        styles={customStyles}
                        onChange={handleDropdownChange}
                        options={dropdownOptions}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                                        <div className="form-group mb-3">
                                            <label>Status Type:</label>
                                            <select name="FeeTypeId" onChange={(e) => setFeeTypeId(e.target.value)} className="form-select">
                                                <option value="0">---All---</option>
                                                <FeetypesDropDown />
                                            </select>
                                        </div>
                                    </div> */}
                  {loaddata ? (
                    <div className="col-md-2 offset-8 text-center">
                      <div>
                        <div>
                          <Spinner animation="border" role="status"></Spinner>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-2 offset-8 text-end mt-2">
                      <div className="form-group text-end ">
                        <a
                          type="button"
                          onClick={handleSubmitForCvs}
                          className="print-icon"
                        >
                          <span>
                            <i className="ri-file-excel-2-line"></i>
                          </span>
                        </a>
                      </div>
                    </div>
                  )}
                  <div className="col-md-2 text-end">
                    <div className="form-group">
                      <button
                        type="submit"
                        value="Search"
                        className="w-100 button button-primary"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <table className="table table-theme">
                <thead>
                  <tr>
                    <th colSpan={4}>{totalRecords} Records Found</th>
                    <th colSpan={11} className="text-end">
                      Total Fee:{DebitSum} Total Concession: {CreditSum} Total
                      Receiveable : {TotalReceiveableFee} Total
                      Received : {TotalReceived} ({receivedCount}) Balance:{" "}
                      {TotalBalance}
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Student ID</th>
                    <th>Registration No</th>
                    <th>Student Name</th>
                    <th>Father Name</th>
                    {/*<th>Wing Name</th> */}
                    <th>Class Name</th>
                    <th>Fee Name</th>
                    <th>Month</th>
                    <th>Year</th>
                    <th>CS</th>
                    <th>Fee</th>
                    <th>Concession</th>
                    <th>Receiveable</th>
                    <th>Received</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td className="text-center" colSpan="15">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                  ) : (
                    <>{renderLedger()}</>
                  )}
                  <tr>
                    <td colSpan={10}></td>
                    <td>{DebitSum}</td>
                    <td>{CreditSum}</td>
                    <td>{TotalReceiveableFee}</td>
                    <td>{TotalReceived}</td>
                    <td>{TotalBalance}</td>
                  </tr>
                </tbody>
              </table>
              {totalRecords > 10 && (
                <div className="pagination-wrap">
                  <div className="row align-items-center">
                    <div className="col-md-2 form_default">
                      <div className="d-flex align-items-center">
                        <label className="me-2" style={{ fontSize: "15px" }}>
                          Page Size
                        </label>
                        <div className="form-group">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => {
                              setPageNo(1);
                              setPageSize(parseInt(e.target.value));
                            }}
                          >
                            <option value="10" selected>
                              10
                            </option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <Pagination
                      pageNo={pageNo}
                      pageSize={pageSize}
                      totalRecords={totalRecords}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="card mt-2">
            <div className="card-body p-md-4">
              <div className="row">
                <div className="col-md-12 text-end">
                  <div className="form-group text-end">
                    <NavLink
                      className="button button-primary"
                      to={`/Reports/FeeReportingPrint?Month=${ReportMonth}&Year=${ReportYear}&Wing_Id=${WingId}&FeeTypeId=${FeetypeID}
                                       `}
                      target="_blank"
                    >
                      Print Report
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeeReporting;