const isLocalhost = window.location.hostname === "localhost";
// const isLiveWebsite = window.location.hostname === "www.djpsmbdin.edu.pk";
const isLiveWebsite = window.location.hostname === "ags.jueducation.com";
const isLocalServer = window.location.hostname === "192.168.1.99";
const isLiveAccounts = window.location.hostname === "ags.jueducation.com";

let base_url;
let path;
let StudentImagePath;
let StaffImagePath;
let images;
let assets;
let root;
let admin_path;


if (isLocalhost) {
  base_url = "http://localhost:52476/api/";
  StaffImagePath = "http://localhost:52476/staff_images/";
  StudentImagePath = "http://localhost:52476/student_images/";
  images = "http://localhost:52476/images/";
  assets = "http://localhost:52476/assets/";
  root = "http://localhost:52476/";

  // base_url = "https://agsapi.jueducation.com/api/";
  // StaffImagePath = "https://agsapi.jueducation.com/staff_images/";
  // StudentImagePath = "https://agsapi.jueducation.com/student_images/";
  // images = "https://agsapi.jueducation.com/images/";
  // assets = "https://agsapi.jueducation.com/assets/";
  // root = "https://agsapi.jueducation.com/";

} else if (isLiveWebsite) {
  base_url = "https://agsapi.jueducation.com/api/";
  StaffImagePath = "https://agsapi.jueducation.com/staff_images/";
  StudentImagePath = "https://agsapi.jueducation.com/student_images/";
  images = "https://agsapi.jueducation.com/images/";
  assets = "https://agsapi.jueducation.com/assets/";
  root = "https://agsapi.jueducation.com/";
} else if (isLiveAccounts) {

  base_url = "https://agsapi.jueducation.com/api/";
  StaffImagePath = "https://agsapi.jueducation.com/staff_images/";
  StudentImagePath = "https://agsapi.jueducation.com/student_images/";
  images = "https://agsapi.jueducation.com/images/";
  assets = "https://agsapi.jueducation.com/assets/";
  root = "https://agsapi.jueducation.com/";
}
else if (isLocalServer) {
  // //apis for mandi bahaudin school
  base_url = "http://192.168.1.99/api/";
  StaffImagePath = "http://192.168.1.99/staff_images/";
  StudentImagePath = "http://192.168.1.99/student_images/";
  images = "http://192.168.1.99/images/";
  assets = "http://192.168.1.99/assets/";
  root = "http://192.168.1.99/";
}



//SeRveR*324*
// Registration No: 8710
//34402-7260112-2
//12345678

/*For AGS School
Admin
Admin*324*
*/
const config = {
  base_url,
  path,
  StudentImagePath,
  StaffImagePath,
  assets,
  root,
  images,
  admin_path,
  date_format: "DD/MM/YYYY",
  date_format_input: "YYYY-MM-DD",
};

export default config;
