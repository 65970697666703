import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import FeeSystemMenu from "./FeeSystemMenu";
import { Link, NavLink } from "react-router-dom";
import imgMoney from "../../assets/images/icons/money.svg";
import imgVolit from "../../assets/images/icons/volit.svg";
import clock from "../../assets/images/icons/clock.svg";
import struck_off from "../../assets/images/dashboard_icon/struck_off.svg";
import arrears from "../../assets/images/dashboard_icon/arrears.svg";
import no_fee from "../../assets/images/dashboard_icon/no_fee.svg";
import Concession from "../../assets/images/dashboard_icon/Concession.svg";
import { toast, ToastContainer } from "react-toastify";
import { scrollToTop } from "../Shared/ScrollOnTop";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";

function FeeSystemDashboard(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dData, setDashboardData] = useState({});
  const [listOfLedger, setLedger] = useState([]);

  useEffect(() => {
    document.title = "Fee System Dashboard";

    setLoading(true);
    const dashboardParms = {
      AccessKey: AccessKey,
      UserID: UserID,
    };
    //console.log(dashboardParms)
    var api_config = {
      method: "post",
      url: config.base_url + "FeeSystem/GetDashboardData",
      headers: {
        "Content-Type": "application/json",
      },
      data: dashboardParms,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response);
        if (response.data.status_code == 1) {
          setDashboardData(response.data);
          setLedger(response.data.ScrollDetail);
          setLoading(false);
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
      })
      .catch(function (error) {
        ////console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{moment(item.Dated).format("DD/MM/YYYY")}</td>
            <td>{moment(item.AddedOn).format("DD/MM/YYYY")}</td>
            <td>{item.NoOfStudents}</td>
            <td>{item.Amount}</td>
            <td>{item.LateDays}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="6">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <FeeSystemMenu />
      <ToastContainer />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/Dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Fee System
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Fee System</div>
              </div>
              <div className="col-md-6">
                <div className="page-right-actions">
                  <Link
                    to="/FeeSystem/PrintVouchers"
                    className="button button-white button-icon"
                  >
                    Print Vouchers
                    <i className="ri-printer-line"></i>
                  </Link>
                  <Link
                    to="/FeeSystem/AddBankScroll"
                    className="button button-white button-icon"
                  >
                    Add Bank Scroll
                    <i className="ri-add-fill"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="page-content">
            <div className="container">
              <div className="row g-3 align-items-center">
                <div className="col-md-8 col-sm-12">
                  <div className="card" style={{ minHeight: "228px" }}>
                    <div className="card-body">
                      <h4 className="card-title">Fee Information</h4>
                      <div className="row align-items-center pt-3">
                        <div className="col-md-4">
                          <div className="icon-box-1 fs-sm m-vertical">
                            <div className="icon">
                              <img src={imgMoney} alt="" />
                            </div>
                            <p>
                              Receivable Fee for <br /> this Month.
                            </p>
                            <h4>Rs. {dData.receiveableFee} </h4>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <NavLink to="/FeeSystem/CollectionReport">
                            <div className="icon-box-1 fs-sm m-vertical">
                              <div className="icon">
                                <img src={imgVolit} alt="" />
                              </div>
                              <p>
                                Total Received <br /> So Far
                              </p>
                              <h4>Rs. {dData.receivedSofar}</h4>
                            </div>
                          </NavLink>
                        </div>
                        <div className="col-md-4">
                          <div className="icon-box-1 fs-sm m-vertical">
                            <div className="icon">
                              <img src={clock} alt="" />
                            </div>
                            <p>
                              Pending <br /> Fee
                            </p>
                            <h4>Rs. {dData.pendingFee} </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="card" style={{ minHeight: "228px" }}>
                    <div className="card-body text-center">
                      <img src={Concession} className="pb-3" alt="" />
                      <p className="m-1">Concession Given This Month</p>
                      <h4 className="m-0">Rs. {dData.concessionGiven} </h4>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-6">
                  <div className="card m-card mobile-card">
                    <div className="card-body text-center">
                      <img src={arrears} className="pb-3" alt="" />
                      <p className="m-2">Arrears of Previous Month</p>
                      <h4 className="m-2">
                        Rs. {dData.arrearsOfPreviousMonth}{" "}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="card m-card">
                    <div className="card-body text-center">
                      <img src={struck_off} className="pb-3" alt="" />
                      <p className="m-2">
                        No. Of Students Struck Off this month.
                      </p>
                      <h4 className="m-2">{dData.noofStruckOffthisMonth} </h4>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="card">
                    <div className="card-body text-center">
                      <img src={no_fee} className="pb-3" alt="" />
                      <p className="m-2">
                        No. of Student Fee not paid in Last month.
                      </p>
                      <h4 className="m-2">{dData.feeNotPaidLastMonth} </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-body staff-attendance">
                  <div className="col-md-12">
                    <div className="row align-middle ">
                      <div className="col-md-6">
                        <h4 className="card-title" style={{ color: "black" }}>
                          Scroll Entry Feeding Statistics{" "}
                        </h4>
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  </div>

                  <div className="page-content mt-1 exam-summary-card">
                    <table className="table table-theme">
                      <thead className="bg-white">
                        <tr>
                          <th>Sr.No</th>
                          <th>Dated</th>
                          <th>Added On</th>
                          <th>NoOfStudents</th>
                          <th>Amount</th>
                          <th>LateDays</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td className="text-center" colSpan="6">
                              <Spinner
                                animation="border"
                                role="status"
                              ></Spinner>
                            </td>
                          </tr>
                        ) : (
                          <>{renderLedger()}</>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeeSystemDashboard;