import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import StudentGroupDropDown from "../Shared/StudentGroupDropDown";
import StudentStatusDropDown from "../Shared/StudentStatusDropDown";
import StudentMenu from "./StudentMenu";
import { toast, ToastContainer } from "react-toastify";
import { NavLink } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import { Form, PageItem } from "react-bootstrap";
import ShowStudent from "../Shared/ShowStudent";
import StudentCard from "../Shared/StudentCard";
import { scrollToTop } from "../Shared/ScrollOnTop";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FeeTypesDropDown from "../Shared/FeeTypesDropDown";
import Select from "react-select";
import { getMonthName } from "../General/Utility";

function StudentLedger(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [UserName, setUserName] = useState(localStorage.getItem("UserName"));
  const [SessionID, setSessionID] = useState(
    localStorage.getItem("DefaultSession")
  );
  const grp_id = localStorage.getItem("User_Group_ID");
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );

  const [currentYear, setCurrentYear] = useState("");
  const [currentMonth, setCurrentMonth] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [RepostLoading, setRepostLoading] = useState(false)

  const [listOfLedger, setLedger] = useState([]);
  const [selectedlistOfLedger, setSelectedLedger] = useState({});
  const [show, setShow] = useState(false);
  const [showForAttachDues, setShowForAttachDues] = useState(false);
  const [loadingAddDuesBtn, setLoadingAddDuesBtn] = useState(false);
  const [FeetypeID, setFeetypeID] = useState(0);
  const [FeeTypes, setFeeTypes] = useState([]);
  const [checkedCurrentData, setCheckedCurrentData] = useState(false);

  const [isUpdated, setIsUpdated] = useState(false);
  const [showLedgers, setShowLedgers] = useState(true);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(1000);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loadingUpdateBtn, setLoadingUpdateBtn] = useState(false);
  const [validatedLedgerUpdate, setValidatedLedgerUpdate] = useState(false);
  const [validatedLedgerAdd, setValidatedLedgerAdd] = useState(false);
  const [test, setTest] = useState(false);
  const [description, setDescription] = useState("");

  const [credit, setCredit] = useState(0);
  const [debit, setDebit] = useState(0);
  const [receiptNo, setReceiptNo] = useState(0);

  let params = useParams();

  const [StudentID, setStudentID] = useState(params.id);
  const [StartDate, setStartDate] = useState("2023-01-01");
  const [EndDate, setEndDate] = useState("2030-06-01");
  const [Datee, setDatee] = useState(new Date());
  const [DueDate, setDueDate] = useState(new Date());

  let totalReceivedAmount = 0;
  let totalPendingFee = 0;
  let navigate = useNavigate();

  const fetchData = () => {
    if (!checkedCurrentData) {
      handleCurrentMonthData();
    } else {
      setIsUpdated(true);
    }
  };

  useEffect(() => {
    document.title = "View Student Ledger";
    const currentDate = moment();
    const year = currentDate.format("YYYY");
    const month = currentDate.format("MM");
    setCurrentYear(year);
    setCurrentMonth(month);

    setIsUpdated(false);
    setLoading(true);
    setLoadings(true);
    fetchData();
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      Student_ID: StudentID,
      StartDate: StartDate,
      EndDate: EndDate,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Student/GetStudentLedger",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setLoadings(false);
          setTotalRecords(response.data.totalRecords);
        } else {
          setLedger(null);
          setTotalRecords(0);
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
          setLoadings(false);
        }
      })

      .catch(function (error) {
        ////console.log(error);
      });
    setTest(false);
  }, [pageNo, pageSize, isUpdated]);

  const handleRepostFee = () => {
    ////console.log(SessionID);
    setRepostLoading(true);
    setIsUpdated(false);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      FYear: currentYear,
      SelectedMonth: currentMonth,
      Student_ID: StudentID,
      SessionID: SessionID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "FeeSystem/RepostFeeForStudent",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        ////console.log(response.data);

        if (response.data.status_code === 1) {
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setRepostLoading(false)
          setTotalRecords(response.data.totalRecords);
          setIsUpdated(true);
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setLedger(null);
          setTotalRecords(0);
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsUpdated(false);
          setLoading(false);
          setRepostLoading(false)

        }
      })
      .catch(function (error) {
        ////console.log(error);
        setRepostLoading(false)

      });
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };
  const handleClose = () => {
    setShow(false);
    setSelectedLedger({});
    setLoadingUpdateBtn(false);
  };
  const handleShow = (item) => {
    setShow(true);
    setSelectedLedger(item);
    setDatee(item.Transaction_Date);
    ////console.log(item);
    // setEmployeeName(item.Name);
  };
  const handleCloseForAttchDues = () => {
    setShowForAttachDues(false);
    // setSelectedLedger({});
    setLoadingAddDuesBtn(false);
  };
  const handleShowForAttchDues = () => {
    setShowForAttachDues(true);

    // setLoading(true);
    const apiData = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      PageNo: 1,
      PageSize: 100,
      SchoolID: 1,
    };

    //////console.log(apiData);

    var api_config = {
      method: "post",
      url: config.base_url + "FeeType/GetFeeTypes",
      headers: {
        "Content-Type": "application/json",
      },
      data: apiData,
    };

    axios(api_config)
      .then(function (response) {
        ////console.log(response.data);

        setFeeTypes(response.data.ListofRecords);
        setLoading(false);
      })
      .catch(function (error) {
        ////console.log(error);
      });

    ////console.log(FeeTypes);
  };
  const dropdownOptions = FeeTypes.map((item) => ({
    value: item.Fee_Type_Code,
    label: item.Fee_Type_Name + "(" + item.Fee_Type_Code + ")",
    code: item.Fee_Type_Code,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%", // Set your desired width here
    }),
  };
  const [selectedValue, setSelectedValue] = useState("");
  const handleDropdownChange = (selectedOption) => {
    //let label = selectedOption.label;
    setFeetypeID(selectedOption.value);
    setSelectedValue(selectedOption.value);
    ////console.log(FeetypeID);
  };
  const handleCheckboxChange = (e) => {
    setCheckedCurrentData(e.target.checked);
    fetchData(); // Fetch data based on the updated checkbox value
  };
  ////console.log(grp_id);
  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        // if (item.Fee_Code != "018") {
        //   totalReceivedAmount += item.Debit;
        // }
        totalReceivedAmount += item.ReceivedAmount;
        totalPendingFee += item.PendingFee;

        return (
          <>
            <tr key={index}>
              <td>{item.ID}</td>
              <td>{moment(item.PostingDate).format("DD/MM/YYYY")}</td>
              <td>{item.FeeCode}</td>
              <td>{item.Fee_Posted}</td>
              <td>{item.ConcessionAmount}</td>
              <td>{item.ReceiveableFee}</td>
              <td>{item.Description}</td>
              <td>{item.ReceivedAmount}</td>
              <td>{moment(item.ReceivedDate).format("DD/MM/YYYY")}</td>
              <td>{item.PendingFee}</td>
              {/* <td>{item.Balance}</td>
                            <td>{item.Balance_Type}</td> */}
              <td className="text-center">
                {/* <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                    <li><button className="dropdown-item" onClick={(e) => deleteStudentLedger(item.Transaction_ID)}><i className="ri-close-circle-fill"></i> Delete</button></li>
                                </ul> */}

                {grp_id == 1 || grp_id == 10 ? (
                  <ul
                    className="inline-action"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <button
                        type="button"
                        className={`green `}
                        onClick={(e) => handleShow(item)}
                      >
                        <i className="ri-edit-line"></i>
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={(e) =>
                          deleteStudentLedger(item.ID)
                        }
                        className={`red`}
                      >
                        <i className="ri-delete-bin-line"></i>
                      </button>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </td>
            </tr>
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="11">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };
  const deleteStudentLedger = (ID) => {
    const confirm = window.confirm("Are you sure you want to proceed ?");
    if (confirm) {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        ID: ID,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Student/DeleteStudentLedger",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });

            setIsUpdated(true);
          }
          // setIsUpdated(false);
        })
        .catch(function (error) {
          ////console.log(error);
        });
    }
  };
  const handleUpdateLedger = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoadingUpdateBtn(true);
      const data = {
        UserID: UserID,
        AccessKey: AccessKey,

        ID: selectedlistOfLedger.ID,
        Transaction_Date: Datee,
        Fee_Code: event.target.FeeCode.value,
        Description: event.target.Description.value,
        ReceiveableFee: event.target.ReceiveableFee.value,
        Debit: event.target.PendingFee.value,
        Concession_Amount: event.target.ConcessionAmount.value,
      };

      //////console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Student/UpdateStudentLedger",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          // //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsUpdated(true);
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          // setIsSubjectAdded(true);
          setLoadingUpdateBtn(false);
          handleClose();
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    setValidatedLedgerUpdate(true);
  };
  const handleAddStudentLedger = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoadingAddDuesBtn(true);

      const data = {
        UserID: UserID,
        AccessKey: AccessKey,
        Student_ID: params.id,
        Transaction_Date: Datee,
        Challan_DueDate: DueDate,
        ReceiptNo: receiptNo,
        Description: description,
        Fee_Code: event.target.Fee_code.value,
        ReceiveableFee: debit,
        FYear: currentYear,
        SelectedMonth: currentMonth,
        User_Name: UserName,
      };

      ////console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Student/AddStudentLedger",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          ////console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });

            handleCloseForAttchDues();
            setIsUpdated(true);
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setLoadingAddDuesBtn(false);
          setCredit(0);
          setDebit(0);
          setReceiptNo(0);
          setDescription("");
          // setIsSubjectAdded(true);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      setValidatedLedgerAdd(true);
    }
  };

  // const handleCurrentMonthData = () => {
  //   const currentDate = moment();
  //   const year = currentDate.format("YYYY");
  //   const month = currentDate.format("MM");
  //   setCurrentYear(year);
  //   setCurrentMonth(month);

  //   setIsUpdated(false);
  //   setLoading(true);

  //   const data = {
  //     AccessKey: AccessKey,
  //     UserID: UserID,
  //     MemberType: MemberType,
  //     pageNo: pageNo,
  //     pageSize: pageSize,
  //     Student_ID: StudentID,
  //     StartDate: StartDate,
  //     EndDate: EndDate,
  //   };
  //   ////console.log(currentMonth, currentYear);
  //   var api_config = {
  //     method: "post",
  //     url: config.base_url + "Student/GetStudentLedger",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   axios(api_config)
  //     .then(function (response) {
  //       ////console.log(response.data);
  //       if (response.data.status_code === 1) {
  //         const filtered = response.data.ListofRecords.filter((val) => {
  //           const dateObj = new Date(val.Transaction_Date);
  //           const month = dateObj.getMonth() + 1;
  //           const formattedMonth = month.toString().padStart(2, "0");

  //           const year = dateObj.getFullYear();
  //           ////console.log(currentYear);
  //           return formattedMonth == currentMonth && year == currentYear;
  //         });
  //         setLedger(filtered);
  //         setLoading(false);
  //         setTotalRecords(response.data.totalRecords);
  //       } else {
  //         setLedger(null);
  //         setTotalRecords(0);
  //         toast.error(response.data.status_message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //         setLoading(false);
  //       }
  //     })
  //     .catch(function (error) {
  //       ////console.log(error);
  //     });
  // };

  const handleCurrentMonthData = () => {
    const currentDate = moment();
    const year = currentDate.format("YYYY");
    const month = currentDate.format("MM");
    setCurrentYear(year);
    setCurrentMonth(month);

    setIsUpdated(false);
    //setLoading(true);

    // const filtered = listOfLedger.filter((val) => {
    //   const dateObj = new Date(val.Transaction_Date);
    //   const month = dateObj.getMonth() + 1;
    //   const formattedMonth = month.toString().padStart(2, "0");

    //   const year = dateObj.getFullYear();
    //   ////console.log(currentYear);
    //   return formattedMonth == currentMonth && year == currentYear;
    // });

    // setLedger(filtered);
    //setLoading(false);
  };

  useEffect(() => {
    if (!loadings) {
      ////console.log(listOfLedger);
      handleCurrentMonthData();
    }
  }, []);

  const handleFeePostedChange = (event) => {
    const feePosted = event.target.value;
    const concessionAmount = event.target.form.ConcessionAmount.value;
    const receiveableFee = feePosted - concessionAmount;
    const pendingFee = receiveableFee - selectedlistOfLedger.ReceivedAmount;
    event.target.form.PendingFee.value = pendingFee;
    event.target.form.ReceiveableFee.value = receiveableFee;
  }

  const handleConcessionAmountChange = (event) => {
    const concessionAmount = event.target.value;
    const receiveableFee = event.target.form.ReceiveableFee.value;
    event.target.form.ReceiveableFee.value = selectedlistOfLedger.Fee_Posted - concessionAmount;
    const pendingFee = selectedlistOfLedger.Fee_Posted - selectedlistOfLedger.ReceivedAmount - concessionAmount;
    event.target.form.PendingFee.value = pendingFee;
  }
  return (
    <>
      <StudentMenu />

      <ToastContainer />

      <Modal className="theme-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Student Ledger </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validatedLedgerUpdate}
            onSubmit={handleUpdateLedger}
          >
            <div style={{ padding: "20px" }}>
              <div className="form-group mb-3 d-flex flex-column">
                <label>Transaction Date</label>

                <DatePicker
                  selected={moment(Datee).toDate()}
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  required
                  name="PostingDate"
                  onChange={(date) => setDatee(date)}
                />

                {/* <input
                  type="date"
                  defaultValue={moment(selectedlistOfLedger.Transaction_Date).format("YYYY-MM-DD")}
                  name="Transaction_Date"
                  className="form-control"
                  required
                /> */}
              </div>
              <div className="form-group mb-3">
                <label>Fee Posted</label>
                <input
                  type="number"
                  defaultValue={selectedlistOfLedger.Fee_Posted}
                  name="FeePosted"
                  className="form-control"
                  required
                  onChange={handleFeePostedChange}
                />
              </div>
              <div className="form-group mb-3">
                <label>Concession Amount</label>
                <input
                  type="number"
                  defaultValue={selectedlistOfLedger.ConcessionAmount}
                  name="ConcessionAmount"
                  className="form-control"
                  required
                  onChange={handleConcessionAmountChange}
                />
              </div>
              <div className="form-group mb-3">
                <label>Receiveable Fee</label>
                <input
                  type="number"
                  defaultValue={selectedlistOfLedger.ReceiveableFee}
                  name="ReceiveableFee"
                  className="form-control"
                  required
                  readOnly
                />
              </div>

              <div className="form-group mb-3">
                <label>Fee Code</label>
                <input
                  type="number"
                  defaultValue={selectedlistOfLedger.FeeCode}
                  name="FeeCode"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label>Description</label>
                <input
                  type="text"
                  defaultValue={selectedlistOfLedger.Description}
                  name="Description"
                  className="form-control"
                  required
                />
              </div>

              <div className="form-group mb-3">
                <label>Received Amount</label>
                <input
                  type="number"
                  defaultValue={selectedlistOfLedger.ReceivedAmount}
                  name="ReceivedAmount"
                  className="form-control"
                  required
                  readOnly
                />
              </div>

              <div className="form-group mb-3">
                <label>Pending Fee</label>
                <input
                  type="number"
                  defaultValue={selectedlistOfLedger.PendingFee}
                  name="PendingFee"
                  className="form-control"
                  required
                  readOnly
                />
              </div>

              {loadingUpdateBtn ? (
                <div className="text-center">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : (
                <button type="submit" className="button button-primary w-100">
                  UPDATE
                </button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        className="theme-modal"
        show={showForAttachDues}
        onHide={handleCloseForAttchDues}
      >
        <Modal.Header closeButton>
          <Modal.Title>Attach Dues </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validatedLedgerAdd}
            onSubmit={handleAddStudentLedger}
          >
            <div style={{ padding: "20px" }}>
              <div className="form-group mb-3">
                <label>Fee Code</label>
                <Select
                  className={`fee_form ${selectedValue.length === 0 ? "red-border" : ""
                    }`}
                  styles={customStyles}
                  onChange={handleDropdownChange}
                  options={dropdownOptions}
                  name="Fee_code"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label>Description</label>
                <input
                  type="text"
                  value={description}
                  name="Description"
                  className="form-control"
                  required
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              <div className="form-group mb-3 d-flex flex-column">
                <label>Dated</label>

                <DatePicker
                  selected={moment(Datee).toDate()}
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  required
                  name="Dated"
                  onChange={(date) => setDatee(date)}
                />
              </div>

              <div className="form-group mb-3">
                <label>Amount</label>
                <input
                  className="form-control"
                  type="number"
                  value={debit}
                  name="Debit"
                  required
                  onChange={(e) => setDebit(e.target.value)}
                  min={0}
                />
              </div>

              {loadingAddDuesBtn ? (
                <div className="text-center">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : (
                <button type="submit" className="button button-primary w-100">
                  Add Dues
                </button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/student/studentdashboard">Students</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Student Ledger
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Student Ledger</div>
              </div>
              <div className="col-md-6">
                <div className="page-right-actions">
                  {/* <NavLink
                    to="/students/AdvanceSearch"
                    className="button button-white"
                  >
                    Back
                  </NavLink> */}
                  <button
                    onClick={() => {
                      setShowLedgers((prevIsActive) => !prevIsActive);
                    }}
                    className="button button-white button-icon"
                  >
                    {showLedgers ? "Hide Detail" : "Show Detail"}
                    <i className="ri-filter-3-line"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <StudentCard StudentID={StudentID} /> */}
        <div
          className={`collapse ${showLedgers ? "show" : ""} `}
          id="collapseFilteres"
        >
          <ShowStudent StudentID={StudentID} />
        </div>
        <div className="page-content mt-3">
          <div className="container">
            <div className="card">
              <div className="card-body">
                <div className="col-md-3">
                  <label>
                    {" "}
                    <i
                      className="ri-calendar-2-line"
                      style={{ color: "blue" }}
                    ></i>{" "}
                    Get Current Month Data
                  </label>
                  <input
                    type="checkbox"
                    checked={checkedCurrentData}
                    className="button button-primary mb-1 ms-2"
                    onChange={(e) => setCheckedCurrentData(e.target.checked)}
                    onClick={() => {
                      if (!checkedCurrentData) {
                        handleCurrentMonthData();
                      } else {
                        setIsUpdated(true);
                      }
                    }}
                  />
                </div>

                <table className="table table-theme">
                  <thead>
                    <tr>
                      <th colSpan="11" className="text-end">
                        {totalRecords} Records Found!
                      </th>
                    </tr>
                    <tr>
                      <th>Transaction ID</th>
                      <th>Dated</th>
                      <th>Fee Code</th>
                      <th>Fee Posted</th>
                      <th>Concession Amount</th>
                      <th>Receiveable Fee</th>
                      <th>Description</th>
                      <th>Received Amount</th>
                      <th>Received Date</th>
                      <th>Pending Fee</th>
                      <th
                        className={`text-center ${grp_id == 1 || grp_id == 10 ? "" : "d-none"}`}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colSpan="11">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>{renderLedger()}</>
                    )}
                    <tr>
                      <td colSpan="6"></td>
                      <td className="text-end">
                        <b> Total : </b>
                      </td>
                      <td>
                        <b>{totalReceivedAmount} </b>
                      </td>
                      <td></td>
                      <td>
                        <b>{totalPendingFee} </b>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colSpan={8}></td>
                      <td>
                        <b> Total Payable : </b>
                      </td>
                      <td>
                        <b>{totalPendingFee}</b>
                      </td>
                      <td colSpan="2"></td>
                    </tr>

                    <tr>
                      <td colSpan={11}>
                        <div className="row">
                          <div className="col-md-2 offset-md-10">
                            {grp_id == 1 || grp_id == 10 || grp_id == 2 ? (
                              <button
                                type="button"
                                className="button button-primary w-100 mt-4"
                                onClick={(e) => handleShowForAttchDues(e)}
                              >
                                Attach Dues
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {totalRecords > 1000 && (
                  <div className="pagination-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-2 form_default">
                        <div className="d-flex align-items-center">
                          <label className="me-2" style={{ fontSize: "15px" }}>
                            Page Size
                          </label>
                          <div className="form-group">
                            <select
                              className="form-select"
                              style={{ width: "100px" }}
                              onChange={(e) => {
                                setPageNo(1);
                                setPageSize(parseInt(e.target.value));
                              }}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="500">500</option>
                              <option value="1000" selected>
                                1000
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <Pagination
                        pageNo={pageNo}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="card mt-3">
              <div className="card-body">
                <div className="row ">
                  <div className="col-md-3">
                    {RepostLoading ?
                      <Spinner animation="border" role="status"></Spinner>
                      :

                      <button
                        className="button button-primary"
                        onClick={handleRepostFee}
                      >
                        Repost This Month Fee
                      </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentLedger;