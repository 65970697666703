import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import QRCode from "react-qr-code";
import StudentInfo from "../FeeSystem/StudentInfo";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from "../General/Utility";

import { toast, ToastContainer } from "react-toastify";

const HeadwiseReceiveableFeePrint = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [Month, setMonth] = useState("");
  const [Year, setYear] = useState("");
  const [Wing, setWing] = useState("");
  const [FeeType, setFeeType] = useState("");
  const [FeeTypeName, setFeeTypeName] = useState("");

  const [Reappear, setReappear] = useState("false");

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [TotalAmount, setTotalAmount] = useState(0);
  const [DebitSum, setDebitSum] = useState(0);
  const [CreditSum, setCreditSum] = useState(0);

  const [ReportMonth, setReportMonth] = useState(moment().format("MM"));
  const [ReportYear, setReportYear] = useState(moment().format("YYYY"));
  const [TotalReceived, setTotalReceived] = useState(0);
  const [TotalBalance, setTotalBalance] = useState(0);
  const [TotalReceiveableFee, setTotalReceiveableFee] = useState(0);

  useEffect(() => {
    document.title = "AGS School Management System";

    setLoading(true);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve individual parameter values
    const month = urlParams.get("Month");
    const year = urlParams.get("Year");
    const wingId = urlParams.get("Wing_Id");
    const feetype = urlParams.get("FeeTypeId");

    // const Reappear = urlParams.get('Reappear');

    setMonth(month);
    setYear(year);
    // setWing(wingId);
    // setFeeType(feetype)

    //console.log(FeeType);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      PageNo: 1,
      PageSize: 10000,
      ReportMonth: month,
      ReportYear: year,
      Wing_Id: wingId,
      FeeTypeId: feetype,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Reports/GetHeadwiseReceiveableFee",

      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(data);
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          //console.log(response.data);
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
          // setTotalAmount(response.data.TotalAmount);
          setFeeTypeName(response.data.Fee_Type_Name);
          setDebitSum(response.data.DebitSum);
          setCreditSum(response.data.CreditSum);
          setTotalReceiveableFee(response.data.TotalReceiveableFee);
          setTotalBalance(response.data.TotalBalance);
          setTotalReceived(response.data.TotalReceived);
        } else {
          setLedger(null);
          setTotalRecords(0);
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        const isPageBreak = index > 0 && index % 43 == 0;
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Father_Name}</td>
            <td>{item.ReceivedAmount}</td>
            <td>{item.ReceiveableFee}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="10">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <ToastContainer />

      <Page orientation="portrait" size="a4">
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <th colSpan={10}>
                <PrintHeader
                  title={`Headwise Receiveable Fee For : ${getMonthName(
                    Month
                  )},${Year}`}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="10">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>
                <tr>
                  <th className="text-end" colSpan={11}>Total Receiveable: {TotalReceiveableFee}
                  </th>
                </tr>
                <tr>
                  <th>Sr. No.</th>
                  <th>Fee Code</th>
                  <th>Fee Type</th>
                  <th>No of Students</th>
                  <th>Total Amount</th>
                </tr>
                {renderLedger()}
                <tr>
                  <td colSpan={4} className="text-end">Total:</td>
                  <td>{TotalReceiveableFee}</td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </Page>
    </>
  );
};

export default HeadwiseReceiveableFeePrint;