import React, { useState, useEffect } from "react";
import Signature from "../../assets/images/Signature.png";
import moment from "moment";
// import Logo from "../../assets/images/Plus_Minua_logo.png";
import Logo from "../../assets/images/SoftSolutionsLogo.png";
import axios from "axios";
import config from "../../Config";
import { useParams } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

const InvoicePrint = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
  const [DefaultFYear, setDefaultFYear] = useState(localStorage.getItem("DefaultFYear"));
  const [Loading, setLoading] = useState(false)
  const [Invoice, setInvoice] = useState({})

  let params = useParams()
  const [ID, setID] = useState(params.id);

  useEffect(() => {

    document.title = "AGS Invoice";
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: ID,
    };
    // console.log(data)

    var api_config = {
      method: 'post',
      url: config.base_url + 'PaymentInvoice/PrintInvoice',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };


    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {

          setInvoice(response.data.InvoiceData)
          setLoading(false);
        }
        else {
          setLoading(false)
        }

      })
      .catch(function (error) {
        //console.log(error);
      });

  }, []);

  return (
    <>

      <div className="page a4 potrait invoice-page">
        <div className="content a4">
          <div className="invoice-header">
            <div className="row align-items-start">
              <div className="col-6">
                <img src={Logo} />
              </div>
            </div>
            <div className="row align-items-center my-3">
              <div className="col-6 ps-5">
                <h1>Invoice</h1>
              </div>
              <div className="col-6">
                <div className="date">
                  <span>Date</span>
                  <p>{moment(Invoice.GeneratedDate).format("1 MMMM , YYYY")}</p>
                </div>
              </div>
            </div>

          </div>
          <div className="invoice-content">
            <p className="">
              As per service agreement with <strong>M/S AGS</strong> , We as Service Provider we have launched new software i.e. School Management System with dashboard support which is completed and functional in December 2024. However now, as per agreement monthly service charges @ Rs. 35,000/- are required to be released as below:
            </p>

            <table className="table table-sm table-bordered table-theme">
              <thead>
                <tr>
                  <th>Sr. #</th>
                  <th>Description</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {
                  Loading ? <tr>
                    <td className="text-center" colspan="70">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr> :
                    <>

                      <tr>
                        <td>1.</td>
                        <td>Service Charges for the Month of {moment(Invoice.GeneratedDate).subtract(1, 'months').format("MMMM YYYY")}</td>
                        <td>35,000/-</td>
                      </tr>
                      <tr>
                        <th></th>
                        <th>Total Amount.</th>
                        <th>Rs. 35,000/-</th>
                      </tr>
                    </>
                }
              </tbody>
            </table>
            <p>
              Please make arrangement for the release of this invoice.
              {/* Please make arrangement for the release of this invoice in favour of "Muhammad Ali Imran" proprietor of M/S Plus Minus Systems Kasur and oblige. */}
            </p>
            <div className="row">
              <div className="col-6 ">
                <h6 className="">
                  <b> Bank Alfalah - Company</b> <br />
                  ------------------------- <br />
                  A/c Title: SOFT SOLUTIONS <br />
                  A/c # 56565001627113 <br />
                  IBAN: PK20ALFH5656005001627113 <br />
                  Bank Alfalah PIA Road Branch Lahore <br />
                  Branch Code: 5656 <br />
                  SWIFT: ALFHPKKAXXX
                </h6>
              </div>
              <div className="col-6 text-end">
                <img style={{ width: "60px", marginRight: '50px' }} src={Signature} alt="" />
                <h6 className="text-end mt-1">
                  Muhammad Ali Imran
                </h6>
              </div>
            </div>
          </div>
          <div className="invoice-footer mt-2">
            <ul>
              <li>+92 321 6590065</li>
              <li>info@softsolutions.com.pk</li>
              <li>www.softsolutions.com.pk</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoicePrint